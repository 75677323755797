import React from "react"
import Layout from "../hoc/Layout"
import classes from "../styles/404.module.styl"

const ErrorPage = (props) => {
  return (
    <Layout location={ props.location } pageTitle="404">
      <p className={ classes.container }>
        This page <span className={ classes.redacted }>is filed away in the actual vault, and it</span> doesn't <span className={ classes.redacted }>have proper coherency yet or is still under construction,</span> exist<span className={ classes.redacted }>ing in a liminal state, and is</span> yet <span className={ classes.redacted }>to be released out in the wild. If you're really curious about the particular note, please let me know and I'll see if it's presentable</span>.
      </p>
    </Layout>
  )
}

export default ErrorPage
